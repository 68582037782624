var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"header-props":_vm.headerProps,"items":_vm.items,"search":_vm.search,"hide-default-header":"","hide-default-footer":!_vm.footer,"footer-props":{
      'items-per-page-options': [_vm.itemsPerPage, 20, 25, 30, 50],
      'items-per-page-text': _vm.$t('table_rows_per_page')
    },"items-per-page":_vm.itemsPerPage,"pageCount":_vm.numberOfPages,"server-items-length":_vm.totalRecords,"disable-pagination":_vm.disablePagination,"no-data-text":_vm.$t('table_no_data_text'),"loading-text":_vm.$t('loading_table_items'),"page":_vm.currentPage,"item-class":_vm.getItemClass},on:{"click:row":_vm.handleClick,"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.value,class:[
              _vm.getSortableClass(header.sortable),
              _vm.getAlignClass(header.align),
              header.class ? header.class : '',
              !props.options.sortDesc[0] &&
              props.options.sortBy[0] === header.value
                ? 'desc'
                : 'asc'
            ],attrs:{"aria-label":header.text,"role":"columnheader","width":header.width ? header.width : ''},on:{"click":function($event){_vm.items.length > 1 && header.sortable ? on.sort(header.value) : ''}}},[(header.helpIcon)?_c('span',[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"color":"white","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#fff","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}],null,true)},[_c('span',{staticClass:"text-333",domProps:{"innerHTML":_vm._s(header.helpText)}})])],1):_c('span',[_vm._v(" "+_vm._s(header.text)+" ")]),(header.sortable)?_c('v-icon',{staticClass:"swap-sorting-icon",attrs:{"size":"15","color":"#fff"}},[_vm._v("$SwapVertical")]):_vm._e()],1)}),0)])]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners)),(!_vm.disablePagination && _vm.totalRecords > 0)?_c('div',{staticClass:"text-center mt-2 pagination-main-outer"},[_c('v-pagination',{staticClass:"elevation-0",attrs:{"length":_vm.numberOfPages},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }